*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, "Noto Sans Symbols 2";
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.tooltip-arrow, .tooltip-arrow:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
  border-style: solid;
  border-color: #e5e7eb;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-right-width: 1px;
}

.tooltip[data-popper-placement^="top"] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltip-arrow {
  left: -4px;
}

.tooltip.invisible > .tooltip-arrow:before {
  visibility: hidden;
}

[data-popper-arrow], [data-popper-arrow]:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  background: inherit;
  width: 9px;
  height: 9px;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #4b5563;
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #4b5563;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

[role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
  visibility: hidden;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

select:not([size]) {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e %3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3e %3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: .75em .75em;
  padding-right: 2.5rem;
}

[dir="rtl"] select:not([size]) {
  background-position: .75rem;
  padding-left: 0;
  padding-right: .75rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: .55em .55em;
  border-color: #0000;
}

[type="checkbox"]:checked {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3e %3c/svg%3e");
  background-repeat: no-repeat;
  background-size: .55em .55em;
}

[type="radio"]:checked, .dark [type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-size: 1em 1em;
}

[type="checkbox"]:indeterminate {
  print-color-adjust: exact;
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M0.5 6h14'/%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: .55em .55em;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .875rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

[dir="rtl"] input[type="file"]::file-selector-button {
  padding-left: 1rem;
  padding-right: 2rem;
}

.dark input[type="file"]::file-selector-button {
  color: #fff;
  background: #4b5563;
}

.dark input[type="file"]::file-selector-button:hover {
  background: #6b7280;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #6b7280;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #6b7280;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

.toggle-bg:after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  background: #fff;
  border-width: 1px;
  border-color: #d1d5db;
  border-radius: 9999px;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  position: absolute;
  top: .125rem;
  left: .125rem;
}

input:checked + .toggle-bg:after {
  border-color: #fff;
  transform: translateX(100%);
}

input:checked + .toggle-bg {
  background: #1c64f2;
  border-color: #1c64f2;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-24 {
  top: 6rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.float-right {
  float: right;
}

.m-4 {
  margin: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.me-1\.5 {
  margin-inline-end: .375rem;
}

.me-3 {
  margin-inline-end: .75rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-9 {
  height: 2.25rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-28 {
  width: 7rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-96 {
  width: 24rem;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.table-fixed {
  table-layout: fixed;
}

.caption-bottom {
  caption-side: bottom;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.scroll-mt-16 {
  scroll-margin-top: 4rem;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-16 {
  gap: 4rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-e-lg {
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-s-lg {
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(28 100 242 / var(--tw-border-opacity, 1));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity, 1));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-lilac-300 {
  --tw-border-opacity: 1;
  border-color: rgb(186 104 200 / var(--tw-border-opacity, 1));
}

.border-lilac-400 {
  --tw-border-opacity: 1;
  border-color: rgb(171 71 188 / var(--tw-border-opacity, 1));
}

.\!bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1)) !important;
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity, 1));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity, 1));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-gray-900\/50 {
  background-color: #11182780;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity, 1));
}

.bg-lilac-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 229 245 / var(--tw-bg-opacity, 1));
}

.bg-lilac-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(123 31 162 / var(--tw-bg-opacity, 1));
}

.bg-lilac-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(106 27 154 / var(--tw-bg-opacity, 1));
}

.bg-lilac-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity, 1));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity, 1));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 202 21 / var(--tw-bg-opacity, 1));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 88 10 / var(--tw-bg-opacity, 1));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-lilac-800 {
  --tw-gradient-from: #6a1b9a var(--tw-gradient-from-position);
  --tw-gradient-to: #6a1b9a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-lilac-500 {
  --tw-gradient-to: #9c27b0 var(--tw-gradient-to-position);
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity, 1));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-lilac-700 {
  --tw-text-opacity: 1;
  color: rgb(123 31 162 / var(--tw-text-opacity, 1));
}

.text-lilac-800 {
  --tw-text-opacity: 1;
  color: rgb(106 27 154 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.decoration-gray-700 {
  text-decoration-color: #374151;
}

.decoration-solid {
  text-decoration-style: solid;
}

.decoration-2 {
  text-decoration-thickness: 2px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@font-face {
  font-family: "Noto Sans Symbols 2";
  src: url("NotoSansSymbols2-Regular-Arrows.aab8937a.woff2");
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lilac-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 229 245 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lilac-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(106 27 154 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.hover\:text-lilac-800:hover {
  --tw-text-opacity: 1;
  color: rgb(106 27 154 / var(--tw-text-opacity, 1));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 131 248 / var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity, 1));
}

.focus\:ring-lilac-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(186 104 200 / var(--tw-ring-opacity, 1));
}

.dark\:border-blue-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.dark\:border-gray-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.dark\:border-transparent:is(.dark *) {
  border-color: #0000;
}

.dark\:\!bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1)) !important;
}

.dark\:bg-blue-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-800\/50:is(.dark *) {
  background-color: #1f293780;
}

.dark\:bg-gray-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-900\/80:is(.dark *) {
  background-color: #111827cc;
}

.dark\:bg-lilac-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(142 36 170 / var(--tw-bg-opacity, 1));
}

.dark\:text-blue-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.dark\:text-gray-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dark\:placeholder-gray-400:is(.dark *)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.dark\:hover\:bg-blue-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-800:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-lilac-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(123 31 162 / var(--tw-bg-opacity, 1));
}

.dark\:hover\:text-blue-500:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-gray-300:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.dark\:focus\:border-blue-500:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.dark\:focus\:ring-blue-500:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 131 248 / var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-gray-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-lilac-900:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity, 1));
}

@media (width >= 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:gap-32 {
    gap: 8rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .md\:hover\:text-lilac-800:hover {
    --tw-text-opacity: 1;
    color: rgb(106 27 154 / var(--tw-text-opacity, 1));
  }

  .md\:dark\:bg-gray-900:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
  }

  .md\:dark\:hover\:bg-transparent:hover:is(.dark *) {
    background-color: #0000;
  }

  .md\:dark\:hover\:text-white:hover:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }
}

@media (width >= 1024px) {
  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:w-1\/6 {
    width: 16.6667%;
  }

  .xl\:w-5\/6 {
    width: 83.3333%;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.ais-Breadcrumb-list, .ais-Carousel-list, .ais-CurrentRefinements-list, .ais-HierarchicalMenu-list, .ais-Hits-list, .ais-FrequentlyBoughtTogether-list, .ais-LookingSimilar-list, .ais-RelatedProducts-list, .ais-TrendingItems-list, .ais-Results-list, .ais-InfiniteHits-list, .ais-InfiniteResults-list, .ais-Menu-list, .ais-NumericMenu-list, .ais-Pagination-list, .ais-RatingMenu-list, .ais-RefinementList-list, .ais-ToggleRefinement-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ais-Carousel-navigation, .ais-ClearRefinements-button, .ais-CurrentRefinements-delete, .ais-CurrentRefinements-reset, .ais-GeoSearch-redo, .ais-GeoSearch-reset, .ais-HierarchicalMenu-showMore, .ais-InfiniteHits-loadPrevious, .ais-InfiniteHits-loadMore, .ais-InfiniteResults-loadMore, .ais-Menu-showMore, .ais-RangeInput-submit, .ais-RefinementList-showMore, .ais-SearchBox-submit, .ais-SearchBox-reset, .ais-VoiceSearch-button {
  font: inherit;
  color: inherit;
  cursor: pointer;
  user-select: none;
  background: none;
  border: 0;
  padding: 0;
  line-height: normal;
  overflow: visible;
}

.ais-Carousel-navigation::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-ClearRefinements-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-CurrentRefinements-delete::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-CurrentRefinements-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-GeoSearch-redo::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-GeoSearch-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-HierarchicalMenu-showMore::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-InfiniteHits-loadPrevious::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-InfiniteHits-loadMore::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-InfiniteResults-loadMore::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-Menu-showMore::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-RangeInput-submit::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-RefinementList-showMore::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-SearchBox-submit::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-SearchBox-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-VoiceSearch-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ais-Carousel-navigation[disabled], .ais-ClearRefinements-button[disabled], .ais-CurrentRefinements-delete[disabled], .ais-CurrentRefinements-reset[disabled], .ais-GeoSearch-redo[disabled], .ais-GeoSearch-reset[disabled], .ais-HierarchicalMenu-showMore[disabled], .ais-InfiniteHits-loadPrevious[disabled], .ais-InfiniteHits-loadMore[disabled], .ais-InfiniteResults-loadMore[disabled], .ais-Menu-showMore[disabled], .ais-RangeInput-submit[disabled], .ais-RefinementList-showMore[disabled], .ais-SearchBox-submit[disabled], .ais-SearchBox-reset[disabled], .ais-VoiceSearch-button[disabled] {
  cursor: default;
}

.ais-InfiniteHits-loadPrevious, .ais-InfiniteHits-loadMore, .ais-HierarchicalMenu-showMore, .ais-Menu-showMore, .ais-RefinementList-showMore {
  overflow-anchor: none;
}

.ais-Breadcrumb-list, .ais-Breadcrumb-item, .ais-Pagination-list, .ais-RangeInput-form, .ais-RatingMenu-link, .ais-PoweredBy {
  align-items: center;
  display: flex;
}

.ais-GeoSearch, .ais-GeoSearch-map {
  height: 100%;
}

.ais-PoweredBy-logo {
  width: auto;
  height: 1.2em;
  display: block;
}

.ais-PoweredBy-text {
  margin-right: .3rem;
}

.ais-RatingMenu-starIcon {
  width: 20px;
  height: 20px;
  display: block;
}

.ais-SearchBox-input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.ais-SearchBox-input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

.ais-SearchBox-input::-webkit-search-decoration {
  display: none;
}

.ais-SearchBox-input::-webkit-search-cancel-button {
  display: none;
}

.ais-SearchBox-input::-webkit-search-results-button {
  display: none;
}

.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.ais-RangeSlider .rheostat {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: visible;
}

.ais-RangeSlider .rheostat-background {
  width: 100%;
  height: 6px;
  top: 0;
}

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px;
}

.ais-RangeSlider .rheostat-background {
  background-color: #fff;
  border: 1px solid #aaa;
  position: relative;
}

.ais-RangeSlider .rheostat-progress {
  background-color: #333;
  height: 4px;
  position: absolute;
  top: 1px;
}

.rheostat-handle {
  z-index: 1;
  cursor: grab;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
}

.rheostat-marker {
  background-color: #aaa;
  width: 1px;
  height: 5px;
  margin-left: -1px;
  position: absolute;
}

.rheostat-marker--large {
  height: 9px;
}

.rheostat-value {
  text-align: center;
  margin-left: 50%;
  padding-top: 15px;
  position: absolute;
  transform: translateX(-50%);
}

.rheostat-tooltip {
  text-align: center;
  margin-left: 50%;
  position: absolute;
  top: -22px;
  transform: translateX(-50%);
}

.ais-Carousel {
  position: relative;
}

.ais-Carousel-list {
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
  grid-auto-columns: 42%;
  grid-auto-flow: column;
  display: grid;
  overflow-x: auto;
}

@media (width >= 999px) {
  .ais-Carousel-list {
    grid-auto-columns: 20%;
  }
}

.ais-Carousel-navigation {
  z-index: 1;
  position: absolute;
  top: 35%;
}

.ais-Carousel-navigation--previous {
  left: 0;
  transform: translateX(-25%);
}

.ais-Carousel-navigation--next {
  right: 0;
  transform: translateX(25%);
}

.ais-Carousel-item {
  scroll-snap-align: start;
}

[class^="ais-"] {
  box-sizing: border-box;
}

[class^="ais-"][class$="--disabled"], [class^="ais-"][class$="--disabled"] *, .ais-VoiceSearch-button:disabled {
  cursor: not-allowed;
}

.ais-HierarchicalMenu-showMore, .ais-Menu-showMore, .ais-RefinementList-showMore, .ais-ClearRefinements-button, .ais-InfiniteHits-loadMore, .ais-RangeInput-submit, .ais-InfiniteHits-loadPrevious, .ais-Pagination-link, .ais-GeoSearch-redo, .ais-GeoSearch-reset, .ais-VoiceSearch-button, .ais-RelevantSort-button, .ais-Carousel-navigation {
  appearance: none;
  color: #23263b;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  justify-content: center;
  place-items: center;
  height: 2rem;
  padding: 0 1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration: none;
  display: inline-flex;
  box-shadow: 0 1px #23263b0d;
}

.ais-HierarchicalMenu-showMore, .ais-Menu-showMore, .ais-RefinementList-showMore, .ais-GeoSearch-redo, .ais-GeoSearch-reset {
  height: 1.5rem;
}

.ais-HierarchicalMenu-showMore, .ais-Menu-showMore, .ais-RefinementList-showMore {
  margin-top: 1rem;
}

.ais-HierarchicalMenu-showMore:hover, .ais-Menu-showMore:hover, .ais-RefinementList-showMore:hover, .ais-ClearRefinements-button:hover, .ais-InfiniteHits-loadMore:hover, .ais-RangeInput-submit:hover, .ais-InfiniteHits-loadPrevious:hover, .ais-Pagination-item:not(.ais-Pagination-item--selected):not(.ais-Pagination-item--disabled) .ais-Pagination-link:hover, .ais-GeoSearch-redo:hover, .ais-GeoSearch-reset:hover, .ais-VoiceSearch-button:hover, .ais-Carousel-navigation:hover {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #d6d6e7;
}

.ais-HierarchicalMenu-showMore:focus, .ais-Menu-showMore:focus, .ais-RefinementList-showMore:focus, .ais-ClearRefinements-button:focus, .ais-InfiniteHits-loadMore:focus, .ais-RangeInput-submit:focus, .ais-InfiniteHits-loadPrevious:focus, .ais-GeoSearch-redo:focus, .ais-GeoSearch-reset:focus, .ais-VoiceSearch-button:focus, .ais-Carousel-navigation:focus {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #3c4fe0;
  outline: none;
  box-shadow: 0 0 0 1px #3c4fe0, 0 2px 0 1px #23263b0d;
}

.ais-HierarchicalMenu-showMore:active, .ais-Menu-showMore:active, .ais-RefinementList-showMore:active, .ais-ClearRefinements-button:active, .ais-InfiniteHits-loadMore:active, .ais-InfiniteHits-loadPrevious:active, .ais-Pagination-item--selected .ais-Pagination-link, .ais-Pagination-link:active, .ais-GeoSearch-redo:active, .ais-GeoSearch-reset:active, .ais-VoiceSearch-button:active, .ais-Carousel-navigation:active {
  border-color: #d6d6e7;
  box-shadow: inset 0 1px 4px #777aaf66, inset 0 1px 1px #777aaf66, 0 1px #23263b0d;
}

.ais-ClearRefinements-button:disabled[disabled], .ais-InfiniteHits-loadMore:disabled[disabled], .ais-InfiniteHits-loadPrevious:disabled[disabled], .ais-Pagination-item--disabled .ais-Pagination-link, .ais-GeoSearch-redo--disabled, .ais-GeoSearch-reset--disabled, .ais-VoiceSearch-button:disabled {
  box-shadow: none;
  color: #b6b7d5;
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
}

.ais-HierarchicalMenu-showMore--disabled[disabled], .ais-Menu-showMore--disabled[disabled], .ais-RefinementList-showMore--disabled[disabled] {
  display: none;
}

.ais-NumericMenu-label, .ais-ToggleRefinement-label, .ais-HierarchicalMenu-item, .ais-Menu-item, .ais-RatingMenu-item, .ais-RefinementList-item {
  font-size: .875rem;
  line-height: 1.75rem;
  display: block;
}

.ais-HierarchicalMenu-link, .ais-Menu-link, .ais-RatingMenu-link, .ais-RefinementList-label {
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.ais-Breadcrumb-link, .ais-HierarchicalMenu-link, .ais-Menu-link, .ais-RatingMenu-link {
  color: inherit;
  text-decoration: none;
}

.ais-Breadcrumb-link:hover {
  text-decoration: underline;
}

.ais-HierarchicalMenu-label, .ais-Menu-label, .ais-RefinementList-labelText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link > .ais-HierarchicalMenu-label, .ais-Menu-item--selected .ais-Menu-label, .ais-RefinementList-item--selected, .ais-RatingMenu-item--selected {
  font-weight: 600;
}

.ais-ToggleRefinement-count, .ais-HierarchicalMenu-count, .ais-Menu-count, .ais-RatingMenu-count, .ais-RefinementList-count {
  color: #23263b;
  background: #f5f5fa;
  border: 1px solid #b6b7d5;
  border-radius: 99999px;
  flex-shrink: 0;
  margin-left: .5rem;
  padding: 0 .25rem;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
  display: inline-flex;
  overflow: hidden;
}

.ais-Menu-noResults, .ais-HierarchicalMenu-noResults, .ais-RefinementList-noResults {
  color: #5a5e9a;
  margin-top: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.ais-MenuSelect-select, .ais-HitsPerPage-select, .ais-SortBy-select {
  appearance: none;
  color: #23263b;
  cursor: pointer;
  font: inherit;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke=\"%23777aaf\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M13 7L7 1 1 7M1 17l6 6 6-6\"/></g></svg>"), linear-gradient(-180deg, #fff, #fcfcfd);
  background-position: right 1rem center, 0 0;
  background-repeat: no-repeat;
  background-size: 10px, auto;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  outline: none;
  width: 100%;
  height: 2.5rem;
  padding: 0 2.5rem 0 1rem;
  position: relative;
  box-shadow: 0 1px #23263b0d;
}

.ais-HitsPerPage-select:-moz-focusring {
  color: #0000;
  outline: none;
}

.ais-HitsPerPage-select::-moz-focus-inner {
  color: #0000;
  outline: none;
}

.ais-SortBy-select:-moz-focusring {
  color: #0000;
  outline: none;
}

.ais-SortBy-select::-moz-focus-inner {
  color: #0000;
  outline: none;
}

.ais-HitsPerPage-select > option:not(:checked), .ais-SortBy-select > option:not(:checked) {
  color: #23263b;
}

.ais-HitsPerPage-select > option:disabled, .ais-SortBy-select > option:disabled {
  color: #b6b7d5;
}

.ais-HitsPerPage-select:hover, .ais-SortBy-select:hover {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke=\"%23777aaf\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M13 7L7 1 1 7M1 17l6 6 6-6\"/></g></svg>"), linear-gradient(-180deg, #fcfcfd, #f5f5fa);
  border-color: #d6d6e7;
}

.ais-HitsPerPage-select:focus, .ais-SortBy-select:focus {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke=\"%23777aaf\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M13 7L7 1 1 7M1 17l6 6 6-6\"/></g></svg>"), linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #3c4fe0;
  box-shadow: 0 0 0 1px #3c4fe0, 0 2px #23263b0d;
}

.ais-HitsPerPage-select:disabled, .ais-SortBy-select:disabled {
  box-shadow: none;
  color: #b6b7d5;
  cursor: not-allowed;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke=\"%23b6b7d5\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M13 7L7 1 1 7M1 17l6 6 6-6\"/></g></svg>"), linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
}

.ais-Panel {
  margin-bottom: 2rem;
}

.ais-Panel-header {
  color: #5a5e9a;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
}

.ais-Panel-footer {
  color: #5a5e9a;
  margin-top: 1rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
}

.ais-Panel--collapsible {
  position: relative;
}

.ais-Panel--collapsible .ais-Panel-collapseButton {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.ais-Panel--collapsed .ais-Panel-body, .ais-Panel--collapsed .ais-Panel-footer {
  display: none;
}

.ais-SearchBox-form {
  background-color: #fff;
  width: 100%;
  height: 2.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  position: relative;
}

.ais-SearchBox-form:before {
  content: "";
  background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E");
  width: 1rem;
  height: 1rem;
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
  left: 1rem;
}

.ais-SearchBox-input {
  appearance: none;
  caret-color: #5a5e9a;
  color: #23263b;
  font: inherit;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  flex: 1;
  max-width: 100%;
  padding-left: 2.5rem;
  box-shadow: inset 0 1px 4px #777aaf4d;
}

.ais-SearchBox-input::placeholder {
  color: #5a5e9a;
}

.ais-SearchBox-input:focus {
  border-color: #3c4fe0;
  outline: none;
  box-shadow: 0 1px #23263b0d;
}

.ais-SearchBox-input:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
}

.ais-SearchBox-input:disabled::placeholder {
  color: #b6b7d5;
  pointer-events: none;
}

.ais-SearchBox-input::-webkit-search-cancel-button {
  appearance: none;
}

.ais-SearchBox-input::-webkit-search-decoration {
  appearance: none;
}

.ais-SearchBox-input::-webkit-search-results-button {
  appearance: none;
}

.ais-SearchBox-input::-webkit-search-results-decoration {
  appearance: none;
}

.ais-SearchBox-loadingIndicator, .ais-SearchBox-reset {
  fill: #484c7a;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.ais-SearchBox-reset:focus {
  fill: #fff;
  background: #b6b7d5;
  outline: 0;
}

.ais-RefinementList-searchBox .ais-SearchBox-loadingIndicator, .ais-RefinementList-searchBox .ais-SearchBox-reset {
  right: .5rem;
}

.ais-SearchBox-loadingIndicator[hidden], .ais-SearchBox-reset[hidden], .ais-SearchBox-submit {
  display: none;
}

.ais-Menu-searchBox, .ais-RefinementList-searchBox {
  margin-bottom: .5rem;
}

.ais-Menu-searchBox .ais-SearchBox-form, .ais-RefinementList-searchBox .ais-SearchBox-form {
  height: 2rem;
}

.ais-Menu-searchBox .ais-SearchBox-form:before, .ais-RefinementList-searchBox .ais-SearchBox-form:before {
  left: .5rem;
}

.ais-Menu-searchBox .ais-SearchBox-input, .ais-RefinementList-searchBox .ais-SearchBox-input {
  padding-left: 2rem;
}

.ais-VoiceSearch-button {
  color: #5a5e9a;
  width: 48px;
  height: 48px;
}

.ais-VoiceSearch-button svg {
  color: currentColor;
}

.ais-Highlight-highlighted, .ais-Snippet-highlighted {
  color: #5468ff;
  background-color: #5468ff1a;
  font-style: normal;
}

.ais-ReverseHighlight-highlighted, .ais-ReverseSnippet-highlighted {
  background: none;
  font-style: normal;
  font-weight: bold;
}

.ais-Hits-item, .ais-InfiniteHits-item, .ais-FrequentlyBoughtTogether-item, .ais-LookingSimilar-item, .ais-RelatedProducts-item, .ais-TrendingItems-item {
  background: #fff;
  align-items: center;
  padding: 1.5rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  display: flex;
  box-shadow: 0 0 0 1px #23263b0d, 0 1px 3px #23263b26;
}

.ais-Hits-item:first-of-type, .ais-InfiniteHits-item:first-of-type, .ais-FrequentlyBoughtTogether-item:first-of-type, .ais-LookingSimilar-item:first-of-type, .ais-RelatedProducts-item:first-of-type, .ais-TrendingItems-item:first-of-type {
  border-radius: 3px 3px 0 0;
}

.ais-Hits-item:last-of-type, .ais-InfiniteHits-item:last-of-type, .ais-FrequentlyBoughtTogether-item:last-of-type, .ais-LookingSimilar-item:last-of-type, .ais-RelatedProducts-item:last-of-type, .ais-TrendingItems-item:last-of-type {
  border-radius: 0 0 3px 3px;
}

.ais-Hits-item:only-of-type, .ais-InfiniteHits-item:only-of-type, .ais-FrequentlyBoughtTogether-item:only-of-type, .ais-LookingSimilar-item:only-of-type, .ais-RelatedProducts-item:only-of-type, .ais-TrendingItems-item:only-of-type {
  border-radius: 3px;
}

.ais-InfiniteHits-loadMore, .ais-InfiniteHits-loadPrevious {
  margin: 1rem auto;
  display: flex;
}

.ais-Hits-banner, .ais-InfiniteHits-banner {
  justify-content: center;
  display: flex;
}

.ais-Hits-banner-image, .ais-InfiniteHits-banner-image {
  max-width: 100%;
}

.ais-GeoSearch {
  position: relative;
}

.ais-GeoSearch-control {
  position: absolute;
  top: 1rem;
  left: 3.75rem;
}

.ais-GeoSearch-label {
  cursor: pointer;
  background-color: #f5f5fa;
  border: 1px solid #b6b7d5;
  border-radius: 3px;
  align-items: center;
  min-height: 1.5rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: inline-flex;
}

.ais-GeoSearch-label, .ais-GeoSearch-redo, .ais-GeoSearch-reset {
  white-space: nowrap;
}

.ais-GeoSearch-reset {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
}

.ais-RefinementList-checkbox, .ais-GeoSearch-input {
  appearance: none;
  color: #d6d6e7;
  cursor: inherit;
  background-color: #fff;
  background-position: 50%;
  background-size: 180%;
  border: 1px solid;
  border-radius: 3px;
  min-width: 1rem;
  height: 1rem;
  margin: 0 .5rem 0 0;
  box-shadow: inset 0 1px 4px #777aaf66;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox, .ais-GeoSearch-input:checked {
  color: #3c4fe0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235468ff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
  background-size: 14px;
  border-color: currentColor;
  box-shadow: inset 0 1px #23263b0d;
}

.ais-RefinementList-checkbox:focus, .ais-GeoSearch-input:focus {
  outline: none;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox:focus, .ais-GeoSearch-input:checked:focus {
  box-shadow: inset 0 1px #23263b0d, 0 0 0 1px;
}

.ais-NumericMenu-label {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.ais-NumericMenu-radio {
  appearance: none;
  background: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 50%;
  outline: 0;
  width: 16px;
  height: 16px;
  margin: 0 .5rem 0 0;
  position: relative;
  box-shadow: inset 0 1px 4px #777aaf4d;
}

.ais-NumericMenu-radio:checked, .ais-NumericMenu-radio:focus {
  border-color: #3c4fe0;
  box-shadow: 0 1px #23263b0d;
}

.ais-NumericMenu-radio:focus {
  box-shadow: 0 0 0 1px #3c4fe0, 0 1px #23263b0d;
}

.ais-NumericMenu-radio:checked:after {
  content: "";
  background: #3c4fe0;
  border-radius: 50%;
  position: absolute;
  inset: 4px;
}

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1.5rem;
}

.ais-HierarchicalMenu-link:before {
  content: "";
  user-select: none;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23b6b7d5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2218%2015%2012%209%206%2015%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  display: inline-block;
}

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link:before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235468ff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
}

.ais-RatingMenu-starIcon {
  fill: #5a5e9a;
  width: 16px;
  margin-right: .25rem;
  position: relative;
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
  fill: #b6b7d5;
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-count, .ais-RatingMenu-item--disabled .ais-RatingMenu-label {
  color: #b6b7d5;
}

.ais-ClearRefinements-button {
  width: 100%;
}

.ais-CurrentRefinements-list {
  grid-auto-flow: column;
  gap: 1rem;
  display: inline-grid;
}

.ais-CurrentRefinements-item {
  background-color: #f5f5fa;
  border: 1px solid #b6b7d5;
  border-radius: 3px;
  align-items: center;
  min-height: 1.5rem;
  padding: .05rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: inline-flex;
}

.ais-CurrentRefinements-category {
  margin-left: .5rem;
  display: flex;
}

.ais-CurrentRefinements-delete {
  color: #9698c3;
  outline-width: 0;
  height: 100%;
  margin-left: .25rem;
}

.ais-ToggleRefinement-label {
  cursor: pointer;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ais-ToggleRefinement-checkbox {
  appearance: none;
  pointer-events: none;
  background: #f5f5fa;
  border-radius: 9999px;
  width: 32px;
  height: 18px;
  position: relative;
  box-shadow: inset 0 1px 4px #777aaf66;
}

.ais-ToggleRefinement-checkbox:checked {
  background: #3c4fe0;
  box-shadow: inset 0 1px 4px #23263b1a;
}

.ais-ToggleRefinement-checkbox:focus {
  outline: 0;
}

.ais-ToggleRefinement-checkbox:after {
  content: "";
  background: linear-gradient(-180deg, #fff, #f5f5fa);
  border-radius: 9999px;
  width: 16px;
  height: 16px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 1px;
  left: 1px;
  box-shadow: 1px 1px 1px #23263b0d;
}

.ais-ToggleRefinement-checkbox:checked:after {
  transform: translateX(14px);
}

.ais-RangeInput-input {
  caret-color: #5a5e9a;
  color: #23263b;
  cursor: text;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  flex: 1;
  align-items: center;
  min-width: 0;
  height: 2rem;
  padding: 0 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 4px #777aaf4d;
}

.ais-RangeInput-input:focus {
  border-color: #3c4fe0;
  outline: 0;
  box-shadow: 0 1px #23263b0d;
}

.ais-RangeInput-separator {
  margin: 0 .5rem;
}

.ais-RangeInput-submit {
  margin-left: .5rem;
}

.ais-RangeSlider .rheostat {
  margin: 40px 6px;
}

.ais-RangeSlider .rheostat-horizontal {
  cursor: pointer;
  width: calc(100% - 15px);
}

.ais-RangeSlider .rheostat-background {
  background-color: #0000;
  border: none;
  border-radius: 3px;
  height: 4px;
  box-shadow: inset 0 1px 3px #0000001a, 0 .5px #ffffff0d;
}

.ais-RangeSlider .rheostat-progress {
  background-color: #3c4fe0;
  border-radius: 3px;
  max-width: 100%;
  height: 4px;
  top: 0;
}

.ais-RangeSlider .rheostat-tooltip {
  user-select: none;
  font-weight: bold;
}

.ais-RangeSlider .rheostat-handle {
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 2px;
  width: 12px;
  height: 24px;
  margin-left: -6px;
  top: -11px;
  box-shadow: 0 1px #23263b0d;
}

.ais-RangeSlider .rheostat-handle:before, .ais-RangeSlider .rheostat-handle:after {
  content: "";
  background-color: #d6d6e7;
  width: 1px;
  height: 12px;
  position: absolute;
  top: 6px;
}

.ais-RangeSlider .rheostat-handle:before {
  left: 4px;
}

.ais-RangeSlider .rheostat-handle:after {
  right: 4px;
}

.ais-RangeSlider .rheostat-marker {
  background-color: #d6d6e7;
}

.ais-Pagination-item:first-child > .ais-Pagination-link {
  border-radius: 3px 0 0 3px;
}

.ais-Pagination-item + .ais-Pagination-item > .ais-Pagination-link {
  border-radius: 0;
  margin-left: -1px;
}

.ais-Pagination-item:last-child > .ais-Pagination-link {
  border-radius: 0 3px 3px 0;
}

.ais-Pagination-item {
  display: inline-block;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  font-weight: bold;
}

.ais-Breadcrumb-link, .ais-Breadcrumb-separator {
  color: #5a5e9a;
}

.ais-Breadcrumb-separator {
  margin: 0 .5rem;
  display: flex;
}

.ais-Breadcrumb-item--selected {
  font-weight: 600;
}

.ais-Breadcrumb-item--selected .ais-Breadcrumb-separator {
  font-weight: normal;
}

@media (width <= 767px) {
  .ais-SearchBox-input, .ais-RangeInput-input {
    font-size: 1rem;
  }
}

@media (hover: none) and (pointer: coarse) {
  .ais-Carousel-navigation {
    display: none;
  }
}

.ais-Carousel:hover .ais-Carousel-navigation:not([hidden]) {
  opacity: 1;
  visibility: visible;
}

.ais-Carousel-navigation, .ais-Carousel-navigation[hidden] {
  opacity: 0;
  visibility: hidden;
}

.ais-Carousel-list {
  outline-offset: .5rem;
  scrollbar-color: #111432 #b6b7d5;
  scrollbar-width: thin;
  outline-color: #3c4fe0;
  gap: .5rem;
  padding-bottom: .5rem;
}

.ais-Carousel-list::-webkit-scrollbar {
  height: 2px;
}

.ais-Carousel-list::-webkit-scrollbar-track {
  background-color: #b6b7d5;
}

.ais-Carousel-list::-webkit-scrollbar-thumb {
  background-color: #111432;
}
/*# sourceMappingURL=index.css.map */
